.App {
  text-align: center;
}

.splash {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.login {
  display: flex;
  flex-direction: column;
  width : 20vh;
  padding: 1vmin;
}

.login-item {
  height: 4vh;
  margin-bottom: 1vmin;
  border: 1px solid black;
}

.button-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1vmin;
}

.flatfileloader-button {
  color: black;
  background-color: #e7e7e7;
  border-radius: 0px;
  border: 1px solid black;
  padding: 0.5vmin;
  width: 15vw;
}

.flatfileloader-button:hover {
  background-color: gray;
  cursor: pointer;
}

.option-panel {
  padding: 1vmin;
}

.option-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 0.5vh;
}

.optional-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.op-item {
  padding: 1vmin;
}

.op-title {
  padding-left: 0.5vmin;
  text-align: left;
  font-size: 20;
  font-weight: bold;
}

.op-item {
  display: flex;
  flex-direction: row;
  padding: 0.5vmin;
  width: 25vw;
}

.op-item-title {
  text-align: left;
  width: 12vw;
}
.op-item-value {
  width: 8vw;
}

.bottom-panel {
  padding: 1vmin;
}